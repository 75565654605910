<div class="es-container">
    <span class="es-header">Easy Street</span>
    <span class="es-header-text">Access a streamlined overview of CCU test results and system health, enabling quick insights and efficient management of your site's performance.</span>
    <div class="custom-container p-t-8">
        <div class="left-content">
          <puc-select-dropdown
            [disableTranslation]="true"
            class="w-200 notranslate select-site"
            [placeholderLabel]="initSiteName"
            [(ngModel)]="selectedSite"
            [data]="sites"
            [displayKey]="'siteName'"
            [noEntriesFoundLabel]="loadingVal"
            [emptyResults]="isEmpty"
            [valueKey]="'siteId'"
            (valueChanged)="selectSite($event)">
          </puc-select-dropdown>
        </div>
        <div class="right-content" *ngIf="!isInValidScore()">
          <img src="assets/images/linkedIn.svg" (click)="shareOnPlatform('linkedin')"  *ngIf="!hideBadge()" matTooltip="Share Badge on LinkedIn" matTooltipPosition="above" [matTooltipClass]="['sharable-icons-tooltip', 'common-style-tooltip']" alt="linkedIn" id="linkedIn-share" class="icon" />
          <img src="assets/images/twitter.svg"  (click)="shareOnPlatform('twitter')" *ngIf="!hideBadge()" matTooltip="Share Badge on Twitter" matTooltipPosition="above" [matTooltipClass]="['sharable-icons-tooltip', 'common-style-tooltip']" alt="twitter" id="twitter-share" class="icon" />
          <img src="assets/images/facebook.svg" (click)="shareOnPlatform('facebook')" *ngIf="!hideBadge()" matTooltip="Share Badge on Facebook" matTooltipPosition="above"  [matTooltipClass]="['sharable-icons-tooltip', 'common-style-tooltip']" alt="facebook" id="faceook-share" class="icon" />
          <img src="assets/images/download.svg"  matTooltip="Download" matTooltipPosition="above" [matTooltipClass]="['sharable-icons-tooltip', 'common-style-tooltip']" [matMenuTriggerFor]="downloadmenu" alt="download" id="download" class="icon" />
        </div>
      </div>
      

    <div class="es-content" *ngIf="siteSelected">
        <div>
            <div>
                <fs-easy-street-gauge [siteScore]="siteScore" [isLoading]="isLoading" id="gauge-section" class="gauge-section"></fs-easy-street-gauge>
            </div>
            <div>
                <fs-easy-street-metrics [siteCheckoutDetails]="siteCheckoutDetails" [isLoading]="isLoading" [selectedSite]="selectedSite" [isSortByEquip]="isSortByEquip" [isRunEnabled]="isRunEnabled"></fs-easy-street-metrics>
            </div>
        </div>
        <div>
            <fs-easy-street-site-report [siteId]="selectedSite" [ccuDetails]="siteCheckoutDetails" [siteCheckListData]="siteCheckListData" (sortEquipPreferChange)="setSortByEquip($event)"></fs-easy-street-site-report>
        </div>
    </div>

    <div class="messageContainer" *ngIf="!siteSelected">
        <div class="messageIcon">
            <i class="fas fa-building fa-3x"></i>
        </div>
        <div class="messageIcon">Select a site to continue</div>
    </div>
</div>


<mat-menu (click)="$event.stopPropagation();" class="download-menu" #downloadmenu="matMenu"
    xPosition="before" id="download-menu">
  <button mat-menu-item (click)="downloadReport()" class="menu-item-download">Download Report</button>
  <button mat-menu-item class="menu-item-download" *ngIf="!hideBadge()" id="badge-download" (click)="downloadBadge()">Download Badge</button>
</mat-menu>
