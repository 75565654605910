import { Component, OnInit } from '@angular/core';
import { SiteService, AlertService, ObjectUtil, AuthenticationService } from '@75f/portal-ui-components';
import { EasyStreetService } from '../../services/easystreet.service';
import { EasystreetExportService } from '../../services/easystreet-export.service';
import { lastValueFrom } from 'rxjs';
import { EasyStreetShareInfoComponent } from '../easy-street-share-info/easy-street-share-info.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'fs-easy-street-layout',
  templateUrl: './easy-street-layout.component.html',
  styleUrls: ['./easy-street-layout.component.scss']
})
export class EasyStreetLayoutComponent implements OnInit {
  initSiteName: string = 'Select Site';
  selectedSite: string;
  siteSelected: boolean = false;
  sites: any[] = [];
  isEmpty = true;
  loadingVal = "Loading...";
  ccuData: any[] = [];
  isLoading = true;
  siteScore: any = null;
  private intervalId: any;
  siteCheckoutDetails: any;
  siteCheckListData: any = {};
  isSortByEquip: boolean = false;
  isRunEnabled: boolean = false;

  constructor(private siteService: SiteService,
    private alertService: AlertService,
    public easyStreetService: EasyStreetService,
    public authService: AuthenticationService,
    private easystreetExportService: EasystreetExportService,
    public dialog: MatDialog
  ) { }

  async ngOnInit(): Promise<void> {

    this.getAllSites();

    if (this.getSelectedSite()) {
      let selectedSite = this.getSelectedSite();
      this.initSiteName = selectedSite.siteName;
      this.easystreetExportService.siteName = selectedSite.siteName;
      this.selectedSite = selectedSite.siteId;
    }

    if (this.selectedSite) {
      await this.loadSiteReport();
    }
  }

  async loadSiteReport() {
    await this.getUserInstallerSites();
    const isInitialLoad = true;
    this.checkOutSiteDetails(isInitialLoad);
    this.fetchingDataOnInterval();
  }

  async getUserInstallerSites() {
    try {
      const userId = this.authService.getUser()?.userId
      const result = await lastValueFrom(this.easyStreetService.getUserSitesWithInstallerRole(userId));
      this.isRunEnabled = result?.sites?.find((site) => site.siteId == '@' + this.selectedSite) ? true : false;
    } catch (err) {
      this.alertService.error(err.error ? err.error.error : 'Something went wrong, please try again');
    }
  }

  // Method to fetch data from the API By Interval
  fetchingDataOnInterval(): void {

    // Clear the existing interval, if any
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    // Start a new interval
    this.intervalId = setInterval(() => {
      this.checkOutSiteDetails();
    }, 60000);
  }

  // Method to check out site details
  checkOutSiteDetails(isInitialLoad?: boolean): void {
    this.isLoading = isInitialLoad ? true : false;
    this.easyStreetService.getSiteCheckOutDetails(this.selectedSite, this.isSortByEquip).subscribe({
      next: (response) => {
        if (response) {
          this.siteCheckoutDetails = response.systems || [];
          this.siteCheckListData = ObjectUtil.deepClone(response);
          this.siteScore = typeof response?.score?.value === "number" && !isNaN(response.score.value)
            ? response.score.value
            : null;
        } else {
          this.siteCheckoutDetails = [];
          this.siteCheckListData = {};
          this.siteScore = null;
        }
        this.isLoading = false; // Set loading to false after receiving response
      },
      error: (err) => {
        this.siteCheckoutDetails = null;
        this.siteScore = null;
        this.alertService.error(err.error ? err.error.error : 'Something went wrong, please try again');
        this.isLoading = false; // Set loading to false on error
      }
    });
  }

  /**
   * Selects a site and initializes the selected site ID and site name.
   *
   * @param site - An array containing site information. The first element of the array
   *               is expected to have `sideId` and `siteName` properties.
   */
  async selectSite(site: any) {
    const self = this;
    self.ccuData = [];
    self.siteCheckListData = {};
    this.selectedSite = site[0].siteId;
    this.initSiteName = site[0].siteName;
    this.easystreetExportService.siteName = this.initSiteName;
    this.siteSelected = true;
    this.siteScore = null;
    this.isSortByEquip = false;
    await this.loadSiteReport();
  }

  /**
   * Fetches all authorized sites and updates the component's state accordingly.
   * 
   * This method performs the following actions:
   * - Initializes the `sites` array to an empty array.
   * - Subscribes to the `getAuthSites` observable from the `siteService`.
   * - If the response contains sites, it maps the `siteId` and `siteName` from the response to the `sites` array.
   * - Sets `isEmpty` to `false` and updates `loadingVal` to "No Results Found".
   * - If a site is stored in local storage under the key 'SelectedSite', it initializes `initSiteName` and `selectedSite` with the stored values.
   * - Logs any errors to the console.
   */
  getAllSites() {
    this.sites = [];
    this.siteService.getAuthSites().subscribe(data => {
      if (data?.sites?.length > 0) {
        this.sites = data.sites.map(({ siteId, siteName }) => ({ siteId: siteId.split('@')[1], siteName }));
        this.isEmpty = false;
        this.loadingVal = "No Results Found";
        if (this.getSelectedSite()) {
          let selectedSite = this.getSelectedSite();
          this.initSiteName = selectedSite.siteName;
          this.selectedSite = selectedSite.siteId;
          this.siteSelected = true;
        }
      }
    }, error => {
      this.alertService.error(error.error ? error.error.msg : 'Something went wrong, please try again');
    });
  }

  getSelectedSite() {
    return JSON.parse(localStorage.getItem('SelectedSite'));
  }

  downloadReport() {
    this.easystreetExportService.generateGlobalPDF();
  }

  shareOnPlatform(platform: 'twitter' | 'linkedin' | 'facebook') {
    
    let isExcellentScore = Math.round((this.siteScore.toFixed(2)) * 100) >= 95;

    this.dialog.open(EasyStreetShareInfoComponent, {
      width: '540px',
      maxWidth: '540px',
      height: '236px',
      minHeight: '236px',
      panelClass: 'social-share-dialog',
      position: { top: '154px' },
      data: {
        platform: platform,
        isExcellentScore: isExcellentScore
      }
    });
  }

  downloadBadge(): void {
    const imageUrl = Math.round((this.siteScore.toFixed(2)) * 100) >= 95 ? 'assets/images/EasyStreetBadgeExcellent.jpg' : 'assets/images/EasyStreetBadgeGood.jpg';
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = imageUrl.split('/').pop() || 'InstallerBadge.jpg';
    link.click();
  }

  isInValidScore() {
    return this.siteScore == null || this.siteScore == undefined;
  }

  hideBadge() {
    const score = this.siteScore == null || this.siteScore == undefined ? NaN : parseFloat(this.siteScore.toFixed(2));

    if (isNaN(score) || score <= 0.75) {
      return true;
    }
  }

  setSortByEquip(isSortByEquip: boolean) {
    this.isSortByEquip = isSortByEquip;
    this.checkOutSiteDetails();
  }


  ngOnDestroy(): void {
    // Clear the interval to avoid memory leaks
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

}
