<div class="report-section">
    <div class="report-title-block">
    <h1 class="report-section-header">Site Checkout Report</h1>
    <span *ngIf="siteHasScore" class="sort-equip-cntr">Sort By Equip</span>
    <mat-slide-toggle *ngIf="siteHasScore" id="sortEquip" [(ngModel)]="isSortByEquip" [ngClass]="isSortByEquip ? 'display-toggle-es-checked' : ''"
    (change)="onSortEquipChange($event)" class="m-l-2"  class="display-toggle-es"></mat-slide-toggle>
    </div>

    <div class="site-punch-list">
        <div class="punch-list-header" *ngFor="let ccu of ccuData; let i = index" [ngClass]="{ active: selectedIndex === i }"(click)="selectCCU(i, $event)">
            {{ ccu.ccuName }}
        </div>
    </div>
     
    <div id="es-export-siteReport" class="list-containers">

        <!--Common loader-->
        <div *ngIf="dataIsLoading">
             <!--Total count display if Data is loading-->
             <div class="punch-list-container">
                <div class="punch-list" *ngFor="let item of [1, 2, 3]">
                    <div>
                        <ngx-skeleton-loader [theme]="{
                        width: '60px',
                        'border-radius': '0',
                        height: '14px',
                        'margin-bottom': '2px',
                       'background-color': '#EBECED'
                      }"></ngx-skeleton-loader>
                    </div>
                    <div>
                        <ngx-skeleton-loader [theme]="{
                        width: '100px',
                        'border-radius': '0',
                        height: '24px',
                        'margin-bottom': '10px',
                        'background-color': '#EBECED'
                      }"></ngx-skeleton-loader>
                    </div>
                </div>
            </div>

              <!--Common Loading Strucutre for Loading the checklist table-->
            <div class="m-t-24" *ngIf="isPunchList">
                <div class="fail-section-header">
                    <h2 class="failed-list-header">Failed Check List</h2>
                </div>
            
                <div class="failed-list-container">
                    <div class="failed-list" *ngFor="let item of [1, 2, 3, 4, 5, 6, 7, 8]">
                        <div>
                            <ngx-skeleton-loader [theme]="{
                                    width: '200px',
                                    'border-radius': '0',
                                    height: '16px',
                                    'margin-bottom': '10px',
                                    'background-color': '#EBECED'
                                  }"></ngx-skeleton-loader>
                        </div>
                        <div>
                            <ngx-skeleton-loader [theme]="{
                                    width: '968px',
                                    'border-radius': '0',
                                    height: '40px',
                                    'margin-bottom': '10px',
                                    'background-color': '#EBECED'
                                  }"></ngx-skeleton-loader>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="m-t-24" *ngIf="!isPunchList">
            
                <div class="check-section-header">
                    <h2 class="check-list-header">Check List</h2>
                </div>
            
                <div class="check-list-container">
                    <div class="check-list" *ngFor="let item of [1, 2, 3, 4, 5, 6, 7, 8]">
                        <div>
                            <ngx-skeleton-loader [theme]="{
                                    width: '200px',
                                    'border-radius': '0',
                                    height: '16px',
                                    'margin-bottom': '10px',
                                    'background-color': '#EBECED'
                                  }"></ngx-skeleton-loader>
                        </div>
                        <div>
                            <ngx-skeleton-loader [theme]="{
                                    width: '702px',
                                    'border-radius': '0',
                                    height: '40px',
                                    'margin-bottom': '10px',
                                    'background-color': '#EBECED'
                                  }"></ngx-skeleton-loader>
                        </div>
                    </div>
                </div>
            </div>

        </div> 

        <!--Content which will be displayed after loaded-->
        <div *ngIf="(!dataIsLoading && siteHasScore) || (!dataIsLoading && siteHasScore)">
            <!--Total count display once Data is loaded-->
            <div  [ngClass]="{'display-flex': isPunchList, 'display-none': !isPunchList}" class="punch-list-container" id="report-meta-data">
                <div class="punch-list" [class]="status.class" *ngFor="let status of totalPunchListScore">
                    <div class="score-name">
                        {{status.name}}
                    </div>
                    <div class="score-value" [ngClass]="status.class">
                        {{status.value}}
                    </div>
                </div>
            </div>
            <div [ngClass]="{'display-flex': !isPunchList, 'display-none': isPunchList}"  class="punch-list-container" id="report-meta-data-ccu">
                <div class="punch-list" [class]="status.class" *ngFor="let status of totalCCUScore">
                    <div class="score-name">
                        {{status.name}}
                    </div>
                    <div class="score-value" [ngClass]="status.class">
                        {{status.value}}
                    </div>
                </div>
            </div>
      
            <!--Punch List Structure for both sort and non-sort-->
            <div class="m-t-24" [ngClass]="{'display-block': isPunchList, 'display-none': !isPunchList}">
                <div class="fail-section-header">
                    <h2 class="failed-list-header">Failed Check List</h2>
                </div>
                <div class="failed-list-container" *ngIf="punchListData?.length">
                    <div class="failed-list" *ngFor="let failedData of punchListData;let i = index"
                        [ngClass]="failedData.status == 'FAIL' ? 'fail-chip' : 'pass-chip'" id="es-punch-list-{{i}}">
                        <div class="failed-test-title">
                            {{failedData.entityName?.length ? failedData.entityName : failedData.ruleName?.length ? failedData.ruleName : ''}}
                        </div>
                        <div class="afected-equips-title">
                            {{isSortByEquip ? 'Failed Checks' : 'Affected Equips'}}
                        </div>
    
                        <!--Chips container if sort by equip is off-->
                        <div class="afected-equips-list-container" *ngIf="!isSortByEquip || failedData.faultLocations?.length">
                            <div *ngFor="let equip of failedData.faultLocations" class="es-chip">{{ equip.name }}</div>
                        </div>
                        <!--Chips container if sort by equip is ON-->
                        <div class="afected-equips-list-container" *ngIf="isSortByEquip || failedData.checks?.length">
                            <div *ngFor="let equip of failedData.checks" class="es-chip">{{ equip.name }}</div>
                        </div>
                    </div>
                </div>

                <div class="no-checklist common-no-checklist" *ngIf="!punchListData?.length">
                    <span *ngIf="(!dataIsLoading && siteHasScore)">No checks failed.</span>
                </div>
    
            </div>
    
            <!--Non-Punch List Structure-->
            <div class="m-t-24" *ngIf="!isPunchList">
    
                <div class="check-section-header">
                    <h2 class="check-list-header">Check List</h2>
                </div>
    
                <!--Structure if sort by equip is OFF-->
                <div class="check-list-container" *ngIf="!isSortByEquip && ccuCheckListData?.length">
                    <div class="check-list"  *ngFor="let ccuCheck of ccuCheckListData;let i = index"
                        [ngClass]="ccuCheck.status == 'FAIL' ? 'fail-chip' : 'pass-chip'"  id="es-check-list-{{i}}">
                        <div class="description">
                            <div class="status-box">
                                <span class="status-text">{{ ccuCheck.status }}</span>
                            </div>
    
                            <div class="check-list-description"> {{ccuCheck?.ruleName}}</div>
    
                        </div>
    
    
                        <div *ngIf="ccuCheck?.status == 'FAIL'">
                            <!--Root Causes-->
                            <div class="check-list-root-cause-header">
                                Root Cause:
                            </div>
                            <div class="check-list-root-cause-details">
                                {{ccuCheck?.rootCauses?.description}}
                            </div>
    
                            <!--Recommendation-->
                            <div class="check-list-root-cause-header">
                                Recommendation:
                            </div>
                            <div class="check-list-root-cause-details">
                                {{ccuCheck?.rootCauses?.recommendedActions}}
                            </div>
    
                            <!--Affected Equips-->
                            <div class="check-list-root-cause-header">
                                Affected Equips
                            </div>
                            <div class="afected-equips-list-container">
                                <div *ngFor="let equip of ccuCheck.faultLocations" class="es-chip">{{ equip.name }}</div>
                            </div>
                        </div>
    
                    </div>

                </div>
    
                <!--Structure if sort by equip is ON-->
                <div class="check-list-container" *ngIf="isSortByEquip && ccuCheckListData?.length">
                    <div class="check-list" *ngFor="let ccuCheck of ccuCheckListData;let i = index"
                        [ngClass]="ccuCheck.status == 'FAIL' ? 'fail-chip' : 'pass-chip'" id="es-check-list-{{i}}">
    
                        <div class="equip-name-header">
                            {{ccuCheck?.entityName}}
                        </div>
    
                        <div *ngIf="ccuCheck?.status == 'FAIL' || ccuCheck?.status == 'PASS'">
                            <div *ngFor="let equipChecks of ccuCheck.checks">
    
                                <!--Test Description-->
                                <div class="description m-t-10">
                                    <div class="status-box" [ngClass]="equipChecks.status == 'FAIL' ? 'sort-equip-fail' : 'sort-equip-pass'">
                                        <span class="status-text">{{ equipChecks.status }}</span>
                                    </div>
                                    <div class="check-list-description"> {{equipChecks?.name}}</div>
                                </div>
    
                                <div *ngIf="equipChecks?.status == 'FAIL'">
                                <!--Root Causes-->
                                <div class="check-list-root-cause-header">
                                    Root Cause:
                                </div>
                                <div class="check-list-root-cause-details">
                                    {{equipChecks?.rootCauses?.description}}
                                </div>
    
                                <!--Recommendation-->
                                <div class="check-list-root-cause-header">
                                    Recommendation:
                                </div>
                                <div class="check-list-root-cause-details">
                                    {{equipChecks?.rootCauses?.recommendedActions}}
                                </div>
                            </div>
    
                                <div class="checkList-separator"
                                    *ngIf="ccuCheck.checks.indexOf(equipChecks) !== ccuCheck.checks.length - 1"> </div>
                            </div>
    
                        </div>
                    </div>
    
                </div>

                <div class="no-checklist common-no-checklist" *ngIf="!ccuCheckListData?.length">
                    <span *ngIf="(!dataIsLoading && siteHasScore)">No checks failed.</span>
                </div>
    
            </div>
    
        </div>

        <!--error message display-->
        <div id="es-run-test-msg" class="no-checklist common-no-checklist" *ngIf="(!dataIsLoading && !siteHasScore)">
            <span>Run Test to generate the Checkout Report.</span>
        </div>

        <!--Timeline Container-->
        <div class="history-list-container" *ngIf="!isPunchList && dataIsLoading && !ccuCheckListData?.length">
                <div class="history-list" *ngFor="let item of [1, 2, 3]">
                    <div>
                        <ngx-skeleton-loader appearance="circle"
                            [theme]="{ width: '14px', height: '14px', margin: '0 8px','background-color': '#EBECED' }">
                        </ngx-skeleton-loader>
                    </div>
                    <div>
                        <div>
                            <ngx-skeleton-loader [theme]="{
                                        width: '140px',
                                        'border-radius': '0',
                                        height: '16px',
                                        'margin-bottom': '4px',
                                        'background-color': '#EBECED'
                                      }"></ngx-skeleton-loader>
                        </div>
                        <div>
                            <ngx-skeleton-loader
                                [theme]="{ width: '180px', 'border-radius': '0', height: '14px','background-color': '#EBECED' }">
                            </ngx-skeleton-loader>
                        </div>
                    </div>
                </div>
            
         </div>

         <div class="history-list-container" *ngIf="!isPunchList  && ccuCheckListData?.length">
            <div class="history-header">History</div>
            <div class="history-list" *ngFor="let item of timeLineHistory; let i = index" [class.selected]="selectedTimeLineIndex === i">
              <!-- Circle Icon -->
              <div class="circle-container" (click)="selectTimeLine(i)">
                <div class="circle-icon"></div>
              </div>
              <!-- Date and User Information -->
              <div>
                <div class="date-time" [class.selected]="selectedTimeLineIndex === i">{{item.date}}
                    <img (click)="downloadCCUData(item)" *ngIf="selectedTimeLineIndex === i" src="assets/images/download.svg" matTooltip="Download Report"
                        matTooltipPosition="above" [matTooltipClass]="'es-download-tooltip-position'"
                         alt="download" id="download" class="download-ccu-icon" />
                </div>
                <div class="email-id" showTooltipOnOverflow  [matTooltip]="item.user" [matTooltipPosition]="'above'">{{item.user}}</div>
              </div>
              <!-- Connecting Line -->
              <div *ngIf="i !== timeLineHistory.length - 1" class="connecting-line"></div>
            </div>
          </div>
          
          
          
    </div>