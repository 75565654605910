<div class="container">
  <div class="header" id="share-header">Share your achievement</div>
  <div class="body" id="share-body-message">
    <div class="text-content">Click ‘Copy & Proceed’ to visit the social media page. The ready-to-use message will be
      copied for easy posting</div>
    <div class="styled-box">
      🎉 Excited to share that I’ve been awarded the <b class="badge-highlight">Smart Building Installation -
        {{badgeType}} Badge!</b> This achievement reflects my dedication to maintaining
      top-tier standards in smart building innovation and exceptional performance.</div>
  </div>
  <div class="footer">
    <button class="cancel-btn btn" id="cancel" (click)="cancel()">CANCEL
    </button>
    <button class="proceed btn" id="proceed-share" (click)="proceedWithShare()">
      COPY & PROCEED
    </button>
  </div>
</div>