import { ConfigurationService } from '@75f/portal-ui-components';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class EasyStreetService {
  siteServiceUrl = this.configService.getConfig('siteAnalyticsUrl');
  caretakerUrl = this.configService.getConfig('auth').caretakerUrl;
  constructor(private httpClient: HttpClient,
    private configService: ConfigurationService) {
  }

  getSiteCheckOutDetails(siteId,groupBy) {
    return this.httpClient.get(`${this.siteServiceUrl}/site-checkout/sites/${siteId}?groupBy=${groupBy?'ENTITY' : 'RULE'}`).pipe(catchError(this.handleError));
  }

  getCCUCheckoutHistory(siteId, ccuId) {
    return this.httpClient.get(`${this.siteServiceUrl}/site-checkout/sites/${siteId}/ccus/${ccuId}/history`).pipe(catchError(this.handleError));
  }

  getCCUCheckoutReportById(siteId, ccuId, runId, groupBy) {
    return this.httpClient.get(`${this.siteServiceUrl}/site-checkout/sites/${siteId}/ccus/${ccuId}/runs/${runId}?groupBy=${groupBy?'ENTITY' : 'RULE'}`).pipe(catchError(this.handleError));
  }

  requestSiteCheckout(siteId, payload) {
    return this.httpClient.post(`${this.siteServiceUrl}/site-checkout/sites/${siteId}`, payload).pipe(catchError(this.handleError));
  }

  cancelOnGoingSiteCheckout(siteId, payload) {
    return this.httpClient.post(`${this.siteServiceUrl}/site-checkout/sites/${siteId}/cancel`, payload).pipe(catchError(this.handleError));
  }

  getUserSitesWithInstallerRole(userId) {
    return this.httpClient.get(`${this.caretakerUrl}/user/${userId}/sites?role=installer`).pipe(catchError(this.handleError));
  }



  private handleError(error: any): Promise<any> {
    return Promise.reject(error);
  }

  isInvalidValue(value) {
    return value === null || value === undefined || value === '';
  }
}
