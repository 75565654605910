import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ECharts } from 'echarts';
@Component({
  selector: 'fs-easy-street-gauge',
  templateUrl: './easy-street-gauge.component.html',
  styleUrls: ['./easy-street-gauge.component.scss']
})
export class EasyStreetGaugeComponent implements OnInit {

  @Input() isLoading = true;
  @Input() siteScore: any = null;
  private chartInstance: ECharts | null = null;
  

  chartOptions: any = {
    backgroundColor: '#FFF',
    tooltip: {
      trigger: 'item',
      formatter: function (params) {
        // Use the color directly from params.color
        const color = params.color;
        return `<span style="background-color: black; opacity: 0.85; color: ${color}; border-radius: 4px; padding: 12px; display: flex; font-weight: 600; font-size: 14px; line-height: 16px">${params.name}</span>`;
      },
      extraCssText: 'background: transparent; border: none; padding: 0px;' // Optional for additional styling
    },
    series: [
      {
        type: 'pie',
        radius: ['75%', '90%'],
        startAngle: 225,
        endAngle: -45,
        animation: false,
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 0
        },
        data: [
          {
            value: 7.5,
            name: 'Needs Focus (<75%)',
            itemStyle: {
              color: '#FF8A4C'
            },
            label: {
              show: false
            },
            labelLine: {
              show: false
            },
            animation: false
          },
          {
            value: 2,
            name: 'Good (75% <> 95%)',
            itemStyle: {
              color: '#FFB200'
            },
            label: {
              show: false
            },
            labelLine: {
              show: false
            }
          },
          {
            value: 0.5,
            name: 'Excellent (>95%)',
            itemStyle: {
              color: '#4CAF50'
            },
            label: {
              show: false
            }
          }
        ]
      },
      {
        type: 'gauge',
        startAngle: 225,
        endAngle: -45,
        center: ['50%', '50%'],
        radius: '95%',
        min: 0,
        max: 1,
        splitNumber: 8,
        axisLine: {
          lineStyle: {
            width: 24,
            color: [
              [0.75, '#FF8A4C'],
              [0.95, '#FFB200'],
              [1, '#4CAF50']
            ]
          }
        },
        pointer: {
          icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
          length: this.siteScore == null || this.siteScore == undefined || this.siteScore == -1 ? '0%' : '13%',
          width: 13,
          offsetCenter: [0, '-56%'],
          itemStyle: {
            color: 'auto'
          }
        },
        tooltip: {
          show: false
        },
        axisTick: {
          length: 6,
          distance: 10,
          lineStyle: {
            color: 'auto',
            width: 1
          }
        },
        splitLine: {
          length: 8,
          distance: 10,
          lineStyle: {
            color: 'auto',
            width: 2
          }
        },
        axisLabel: {
          show: false
        },
        title: {
          offsetCenter: [0, '1%'],
          fontSize: this.siteScore == null || this.siteScore == undefined || this.siteScore == -1  ? 18 : 22,
          fontWeight: 700,
          color: this.getColor(this.siteScore)
        },
        detail: {
          fontSize: 32,
          offsetCenter: [0, '-25%'],
          valueAnimation: true,
          formatter: function (val) {
            // Return the value as a percentage, except for 0 and null
            return val === null || val === undefined || val === -1 ? '--' : `${Math.round((val.toFixed(2)) * 100)}%`;
          },
          color: this.getColor(this.siteScore)
        },
        data: this.getGaugeData(this.siteScore)
      },
      {
        type: 'gauge',
        center: ['50%', '50%'],
        radius: '95%',
        splitNumber: 20, // This splits the gauge into 4 sections, but we will customize label visibility
        axisLine: {
          lineStyle: {
            width: 24,
            color: [
              [0, '#FFFFF'],
              [0.75, '#FF8A4C'], // 75%
              [0.95, '#FFB200'], // 95%
              [1.0, '#4CAF50']
            ]
          }
        },
        pointer: {
          show: false
        },
        axisTick: {
          show: false
        },
        splitLine: {
          show: false
        },
        axisLabel: {
          show: true,
          color: '#999999',
          fontSize: 12,
          distance: -40,
          rotate: 20,
          formatter: function (value) {
            if (value === 75) {
              return value + '%';
            }
            return ''; // Hide other labels
          }
        },
        title: {
          show: false
        },
        detail: {
          show: false
        },
        data: []
      },
      {
        type: 'gauge',
        center: ['50%', '50%'],
        radius: '95%',
        splitNumber: 20, // This splits the gauge into 4 sections, but we will customize label visibility
        axisLine: {
          lineStyle: {
            width: 24,
            color: [
              [0, '#FFFFF'],
              [0.75, '#FF8A4C'], // 75%
              [0.95, '#FFB200'], // 95%
              [1.0, '#4CAF50']
            ]
          }
        },
        pointer: {
          show: false
        },
        axisTick: {
          show: false
        },
        splitLine: {
          show: false
        },
        axisLabel: {
          show: true,
          color: '#999999',
          fontSize: 12,
          distance: -40,
          rotate: -30,
          formatter: function (value) {
            if (value === 95) {
              return value + '%';
            }
            return ''; // Hide other labels
          }
        },
        title: {
          show: false
        },
        detail: {
          show: false
        },
        data: []
      }
    ]
  };

  getColor(value) {
    if (value === null || value === undefined || value === -1) {
      return '#999999'; // Gray for null/undefined
    }
    if (value <= 0.75) {
      return '#FF8A4C'; // Orange for Needs Focus
    }
    if (value <= 0.95) {
      return '#FFB200'; // Yellow for Good
    }
    return '#4CAF50'; // Green for Excellent
  }

  // Function to generate data based on value
  getGaugeData(value) {
    return [
      {
        value: value === null || value === undefined  ? -1 : value, // Pass "-" if value is null or undefined
        name: value === null || value === undefined || value === -1 ? 'Installation Score' : value <= 0.75 ? 'Needs Focus'
          : value > 0.75 && value <= 0.95 ? 'Good' : 'Excellent'
      }
    ];
  }


  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    let siteScore = changes?.siteScore?.currentValue ?? null;
    this.chartOptions.series[1].animation =  siteScore == null || siteScore == undefined || siteScore == -1 ? false: true;
    this.chartOptions.series[1].data = this.getGaugeData(siteScore);
    this.chartOptions.series[1].pointer.length = siteScore == null || siteScore == undefined || siteScore == -1 ? '0%' : '13%';
    this.chartOptions.series[1].title.color = this.getColor(siteScore);
    this.chartOptions.series[1].title.fontSize = siteScore == null || siteScore == undefined || siteScore == -1 ? 18 : 22;
    this.chartOptions.series[1].detail.color = this.getColor(siteScore);
    if (changes['siteScore'] && !changes['siteScore'].isFirstChange()) {
      this.chartInstance?.setOption(this.chartOptions);
    }
  }

  onChartInit(chart: ECharts) {
    this.chartOptions.series[1].animation =  this.siteScore == null || this.siteScore == undefined || this.siteScore == -1 ? false: true;
    this.chartOptions.series[1].data = this.getGaugeData(this.siteScore);
    this.chartOptions.series[1].pointer.length = this.siteScore == null || this.siteScore == undefined || this.siteScore == -1 ? '0%' : '13%';
    this.chartOptions.series[1].title.color = this.getColor(this.siteScore);
    this.chartOptions.series[1].title.fontSize = this.siteScore == null || this.siteScore == undefined || this.siteScore == -1 ? 18 : 22;
    this.chartOptions.series[1].detail.color = this.getColor(this.siteScore);
    this.chartInstance = chart;
    chart.setOption(this.chartOptions);
  }

}
