<header class="fs-global-topbar">
    <div class="d-flex">
        <div id="open-close-sidebar" [ngClass]="(!_isDashboardPage) ? 'fs-global-topbar__icon' : 'fs-global-topbar__hideicon'">
            <i class="fas fa-bars" *ngIf="!_isDashboardPage" (click)="openSidebar()"></i>
        </div>
        <div id="logo" class="fs-global-topbar__logo" [ngClass]=" !_isDashboardPage ? 'p-l-20' : 'p-l-32'">
            <a class="fs-global-topbar-item__logo--brand" [routerLink]="['/']">
                <img src="assets/images/logo.svg" alt="" />
            </a>
        </div>
        <div class="fs-global-topbar__userbuliding" [hidden]="_isDashboardPage">
            <i class="fas fa-building active-site-icon"></i>
            <div class="un-icon">
                <span class="fs-global-topbar__userbuliding--name notranslate">{{siteName}}</span>
            </div>
        </div>
    </div>

    <div class="fs-global-topbar__toolbar">
        <ul class="right-side-header">
            <li class="fs-global-topbar__toolbar__listitem--img">
                <img src="assets/images/POWERED_BY_75F.svg" alt="" />
            </li>
            <span></span>
            <li id="alert-notifications" (click)="navigateToAlerts()" class="fs-global-topbar__toolbar__listitem p-r-12 p-l-12">
                <i class="fas fa-bell alert-bell-icon"></i>
                <sup>
                    <span class="fs-global-topbar__toolbar__listitem--badge notranslate">{{(totalAlertCount | pucDecimalPipe) ? formatAlertsCount(totalAlertCount) : '' }}</span>
                </sup>
             </li>
            <li class="fs-global-topbar__toolbar__listitem">
                <div class="fs-topbar__logo__topnav__dropdown">
                    <button id="show-menu" class="fs-topbar__logo__topnav__dropdown__dropbtn">
                        <i class="fas fa-th menu-icon"></i>
                    </button>
                    <div class="fs-topbar__logo__topnav__dropdown__dropdown-content fixedPosition">
                        <a id="user-management" (click)="navigateToUserManagement()" [routerLinkActive]="['active']">USER MANAGEMENT</a>
                        <a id="dashboards" *ngIf="checkCertificationAccess('customDashboards') && showCustomDashboards" (click)="navigateToCustomDashboards()"  [routerLinkActive]="['active']">DASHBOARDS</a>
                        <a id="portfolio-analytics" *ngIf="showPAM" (click)="navigateToEnergyBoard()"  [routerLinkActive]="['active']">Portfolio Analytics</a>
                        <a id="named-schedule" *ngIf="showNamedSchedules" (click)="navigateToNamedSchedule()"  [routerLinkActive]="['active']">NAMED SCHEDULE</a>
                        <a id="alert-configuration" *ngIf="checkCertificationAccess('alertConfiguration')" (click)="navigateToAlertConfiguration()"  [routerLinkActive]="['active']">ALERT CONFIGURATION</a>
                        <a id="audit-trail" *ngIf="checkCertificationAccess('auditTrail')" (click)="navigateToAuditTrail()" [routerLinkActive]="['active']">AUDIT TRAIL</a>
                        <a id="site-manager" *ngIf="checkCertificationAccess('siteManager')" (click)="navigateToSiteManager()" [routerLinkActive]="['active']">SITE MANAGER</a>
                        <a id="energy-configuration" *ngIf="checkCertificationAccess('energyConfiguration')"  [routerLinkActive]="['active']" (click)="navigateToEnergyConfiguration()">Energy Configuration</a>
                        <a id="site-sequencer" *ngIf="checkCertificationAccess('siteSequencer') && showSiteSequencer" (click)="navigateToSiteSequencer()"  [routerLinkActive]="['active']">Site Sequencer</a>
                        <a id="easy-street" *ngIf="allowEasyStreetAccess" (click)="navigateToEasyStreet()"  [routerLinkActive]="['active']">Easy Street</a>
                        <a id="help-center" href="{{helpCenter}}" target="_blank" [routerLinkActive]="['active']">Help Center</a>
                        <a id="about" (click)="openAboutInfo()" [routerLinkActive]="['active']">About</a>
                    </div>
                </div>
            </li>
            <li class="fs-global-topbar__toolbar__listitem">
                <div class="fs-topbar__logo__topnav__dropdown">
                    <button class="fs-topbar__logo__topnav__dropdown__dropbtn">
                        <span> Hi <span [matTooltip]="" [matTooltipPosition]="'left'" showTooltipOnOverflow [matTooltipClass]="'user-name-tooltip'"
                                  class="user-name"> {{userName}} </span>
                            <i class="dropdown-arrow fa fa-angle-down arrow-down p-l-2" id="account-details-menu"></i>
                        </span>
                    </button>
                    <div class="fs-topbar__logo__topnav__dropdown__dropdown-content">
                        <a id="account" [routerLink]="['/account']" [routerLinkActive]="['active']">ACCOUNT</a>
                        <a id="app-log-out" (click)="logout()">logout</a>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</header>