import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PucComponentsModule } from '@75f/portal-ui-components';
import { EasyStreetLayoutComponent } from './components/easy-street-layout/easy-street-layout.component';
import { RouterModule, Routes } from '@angular/router';
import { EasyStreetGaugeComponent } from './components/easy-street-gauge/easy-street-gauge.component';
import { EasyStreetMetricsComponent } from './components/easy-street-metrics/easy-street-metrics.component';
import { EasyStreetSiteReportComponent } from './components/easy-street-site-report/easy-street-site-report.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxEchartsModule } from 'ngx-echarts';
import * as echarts from 'echarts';
import 'echarts-gl';
import { SharedModule } from '@/shared/shared.module';
import { CountdownModule } from 'ngx-countdown';
import { EasyStreetShareInfoComponent } from './components/easy-street-share-info/easy-street-share-info.component';

const routes: Routes = [
    { path: '', component: EasyStreetLayoutComponent }
];


@NgModule({
  declarations: [
    EasyStreetLayoutComponent,
    EasyStreetGaugeComponent,
    EasyStreetMetricsComponent,
    EasyStreetSiteReportComponent,
    EasyStreetShareInfoComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    FormsModule,
    PucComponentsModule,
    NgxSkeletonLoaderModule,
    SharedModule,
    CountdownModule,
    NgxEchartsModule.forRoot({ echarts }),
    SharedModule
  ],
  entryComponents:[
    EasyStreetLayoutComponent
  ]
})
export class EasyStreetModule { }
