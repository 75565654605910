import { AlertService, ArrayUtil, HelperService } from '@75f/portal-ui-components';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild ,OnDestroy, ElementRef, Output, EventEmitter } from '@angular/core';
import { EasyStreetService } from '../../services/easystreet.service';
import { catchError, EMPTY, switchMap, throwError } from 'rxjs';
import dayjs from 'dayjs';
import { EasystreetExportService } from '../../services/easystreet-export.service';
@Component({
  selector: 'fs-easy-street-site-report',
  templateUrl: './easy-street-site-report.component.html',
  styleUrls: ['./easy-street-site-report.component.scss']
})
export class EasyStreetSiteReportComponent implements OnInit, OnChanges, OnDestroy {
  isPunchList = true;
  dataIsLoading = true;
  totalCCUScore :any[] = [];
  totalPunchListScore: any = [];
  selectedIndex: number = 0;
  ccuCheckListData:any [] = [];
  @Input() ccuDetails: any;
  @Input() siteId: boolean;
  @Input() siteCheckListData: any = {};
  @Output() sortEquipPreferChange: any = new EventEmitter();
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;
  ccuData: any = [];
  isSortByEquip: boolean = false;
  punchListData: any = {};
  totalStats: any = {};
  siteHasScore: any = false;
  timeLineHistory: any [] = [];
  selectedTimeLineIndex: number = 0;
  selectedCCUTimeline: boolean = false;

  constructor(private helperService: HelperService,private easyStreetService:EasyStreetService, private alertService: AlertService,private easystreetExportService:EasystreetExportService) { }
 
  /**On changes of ccuDetails, siteCheckListData and siteId in easy-metric-layout, this method will be triggered
   * site score,punchlist and loader status are set here*/
  ngOnChanges(changes: SimpleChanges) {
    if (changes?.ccuDetails?.currentValue) {
      this.ccuData = [{ ccuName: 'Punch List', isPunchList: true }, ...changes?.ccuDetails?.currentValue];
      this.refreshCCUData();
    }
  
    if (changes?.siteCheckListData?.currentValue && Object.keys(changes.siteCheckListData.currentValue).length !== 0 && this.isPunchList) {
      this.siteCheckListData = changes?.siteCheckListData?.currentValue;
      this.siteHasScore = this.siteCheckListData?.punchList?.length > 0;
      this.easystreetExportService.siteHasScore = this.siteHasScore;
      this.punchListData = this.siteCheckListData?.punchList?.filter(item => item.status === 'FAIL');
      const totalStats = this.siteCheckListData?.score?.statistics;

      this.totalPunchListScore = [
        { name: 'Total Checks', value: totalStats?.total, class: 'total-checks' },
        { name: 'Passed', value: totalStats?.passed, class: 'passed' },
        { name: 'Failed', value: totalStats?.failed, class: 'failed' }
      ];
      this.dataIsLoading = false;
    }
  
    if (changes?.siteId?.currentValue) {
      // Clear all data on site change
      this.ccuCheckListData = [];
      this.punchListData = [];
      this.selectedIndex = 0;
      this.isPunchList = true;
      this.dataIsLoading = true;
      this.totalCCUScore = [];
      this.totalPunchListScore = [];
      this.isSortByEquip = false;
      this.siteHasScore = false;
      this.easystreetExportService.siteHasScore = this.siteHasScore;
    }
  }

  ngOnInit() {

  }

  /**Handles the API call for fetching ccu-checkout report for both
   * sort and non-sort by equip
   * Re-adjusts scroll based on selected ccus' position in UI.
   */
  selectCCU(index: number,event): void {
   this.selectedIndex = index;
    this.dataIsLoading = true;
    this.selectedTimeLineIndex = 0;
    this.ccuCheckListData = [];
   //adjust the scroll position in order to show the selected ccu in the middle of the screen 
   const target = (event.target as HTMLElement).closest('.punch-list-header');
    if (target) {
      target.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    }

    if (!this.ccuData[index].isPunchList) {
      this.isPunchList = false;
      this.getCCUChecklistData(index);
    } else {
      this.isPunchList = true;
      this.sortEquipPreferChange.emit(this.isSortByEquip);
    }
  }

  /**Based on selected CCU api will be triggered
   * If user is in puch list, then site-level checkout will be fetched bu triggering sortEquipPreferChange.
   * If user is in CCU list, then CCU checkout will be fetched by triggering getCCUChecklistData.
   * @param event
   */
  onSortEquipChange(event) {
    this.isSortByEquip = event.checked;
    this.dataIsLoading = true;
    this.sortEquipPreferChange.emit(this.isSortByEquip);
    if (!this.isPunchList) {
      this.getCCUChecklistData(this.selectedIndex);
    }
  }

  /**API call to fetch CCU checkout report data based on selected CCU and based on sort by equip
   * @param index
   */
  getCCUChecklistData(index: number,onRefresh?) {
    const ccuId = this.helperService.stripHaystackTypeMapping(this.ccuData[index].ccuId);
    // Get the runId for the selected CCU
    this.easyStreetService.getCCUCheckoutHistory(this.siteId, ccuId).pipe(
      switchMap((data: any) => {
        if (!data || data.length === 0) {
          this.dataIsLoading = false;
          this.siteHasScore = false;
          return EMPTY;
        } else {
          data = data?.map(item => {
            const formattedDate = dayjs(item?.timestamp)?.format('MMM DD, YYYY | hh:mm A');
            const exportDate = dayjs(item?.timestamp)?.format('DD-MM-YYYY-hh:mm A');
            return { ...item, date: formattedDate, exportDate: exportDate };
          });

          const previousSelectedId = this.timeLineHistory?.[this.selectedTimeLineIndex]?.runId;
          data = data?.slice(0, 10);
          this.timeLineHistory = data;

          if (onRefresh) {
            const findPreviousSelectedId = this.timeLineHistory?.findIndex(item => item?.runId === previousSelectedId);
            if (findPreviousSelectedId !== -1) {
              this.selectedTimeLineIndex = findPreviousSelectedId;
            } else {
              this.selectedTimeLineIndex = this.timeLineHistory?.length - 1
            }
          }

        // By Default first runId from timeline array is selected
        return this.easyStreetService.getCCUCheckoutReportById(this.siteId, ccuId, onRefresh ? this.timeLineHistory[this.selectedTimeLineIndex]?.runId : data[0]?.runId, this.isSortByEquip);
        }
      }),
      catchError((error) => {
        this.alertService.error(error.error ? error?.error?.error : 'Something went wrong, please try again');
        this.dataIsLoading = false;
        return throwError(() => error);
      })
    ).subscribe({
      next: (finalData) => {
        this.formatTimeLineData(finalData);
      },
      error: (error) => {
        this.alertService.error(error.error ? error?.error?.error : 'Something went wrong, please try again');
        this.dataIsLoading = false;
      }
    });
  }

  /**If there are multiple root-causes and recommendation actions, then combine them into a  single string by adding ;
   * this method is called when sort by equip is false **/
  formatCCUDataRule(item) {
    const combinedRootCauses = item?.rootCauses?.reduce((acc, cause) => {
      acc?.description?.push(cause?.description);
      acc?.recommendedActions?.push(...cause?.recommendedActions);
      return acc;
    }, { description: [], recommendedActions: [] });
  
    return {
      ...item,
      rootCauses: {
        description: combinedRootCauses?.description?.length > 1
          ? combinedRootCauses?.description.join('; ')
          : combinedRootCauses?.description[0],
        recommendedActions: combinedRootCauses?.recommendedActions?.length > 1
          ? combinedRootCauses?.recommendedActions?.join('; ')
          : combinedRootCauses?.recommendedActions[0]
      }
    };
  }
  
    /**If there are multiple root-causes and recommendation actions, then combine them into a  single string by adding ;
   * this method is called when sort by equip is true **/
  formatCCUDataEntity(entity) {
    const updatedChecks = entity?.checks?.map(check => {
      const combinedRootCauses = check?.rootCauses?.reduce((acc, cause) => {
        acc?.description?.push(cause?.description);
        acc?.recommendedActions?.push(...cause?.recommendedActions);
        return acc;
      }, { description: [], recommendedActions: [] });
  
      return {
        ...check,
        rootCauses: {
          description: combinedRootCauses?.description.join('; '),
          recommendedActions: combinedRootCauses?.recommendedActions.join('; ')
        }
      };
    });
  
    return {
      ...entity,
      checks: updatedChecks
    };
  }

  /**Based on selected time, corresponding ccu-checkout report will be fetched
   * @param index
   */
  selectTimeLine(index: number) {
    this.selectedTimeLineIndex = index;
    this.selectedCCUTimeline = true;
    this.dataIsLoading = true;
    this.updateCCUChecklistData();
  }

  /**API call to fetch CCU checkout report data based on selected CCU and based on sort by equip
   * This method is common for both sort and non-sort by equip
   * It is also a common method to trigger api on selecting ccu or for 1-min-interval refresh
   */
  updateCCUChecklistData() {
    const ccuId = this.helperService.stripHaystackTypeMapping(this.ccuData[this.selectedIndex].ccuId);
    this.easyStreetService.getCCUCheckoutReportById(this.siteId,ccuId,this.timeLineHistory[this.selectedTimeLineIndex]?.runId,this.isSortByEquip).subscribe({
      next: (finalData) => {
        this.formatTimeLineData(finalData);
      },
      error: (error) => {
        this.alertService.error(error.error ? error?.error?.error : 'Something went wrong, please try again');
        this.dataIsLoading = false;
      }
    });
  }

  /**Based CCU Report based on timeline, formatting of key names and sorting values are done here */
  formatTimeLineData(finalData) {
    this.siteHasScore = finalData?.report?.results?.length;
    if (finalData?.report?.results?.length) {
      if (!this.isSortByEquip) {
        finalData.report.results = finalData.report.results.map(item => this.formatCCUDataRule(item));
      } else {
        finalData.report.results = finalData.report.results.map(entity => this.formatCCUDataEntity(entity));
        //Sort checks of every equip(valid when sort by equip is On)
        finalData?.report?.results?.forEach(entity => { entity?.checks?.sort((a, b) =>  a?.status?.localeCompare(b?.status))});
      }
    }
    this.ccuCheckListData = ArrayUtil.deepFlatten(finalData?.report?.results);
    this.ccuCheckListData = this.ccuCheckListData.filter((item) => item.status == 'FAIL' || item.status == 'PASS');
    this.ccuCheckListData = this.ccuCheckListData.sort((a, b) => a.status.localeCompare(b.status));
    this.totalCCUScore = [
      { name: 'Total Checks', value: finalData?.report?.score?.statistics?.total, class: 'total-checks' },
      { name: 'Passed', value: finalData?.report?.score?.statistics?.passed, class: 'passed' },
      { name: 'Failed', value: finalData?.report?.score?.statistics?.failed, class: 'failed' }
    ];
    this.dataIsLoading = false;

  }

  /**On every 1-minute interval, this method will be triggered to refresh the data
   if user is on ccu list*/ 
  refreshCCUData() {
    if (this.isPunchList) return;
    this.getCCUChecklistData(this.selectedIndex, true);
  }
  
// Download CCU report 
  downloadCCUData(reportData){
    this.easystreetExportService.exportCCUData(this.ccuData[this.selectedIndex].ccuName,reportData);
  }
  

  ngOnDestroy() {
  }
  
}
