import { Component, OnInit } from '@angular/core';
import { MessageService, HelperService, ConfigurationService, NotificationsService, CommonMethodService, FeatureToggleService } from '@75f/portal-ui-components';
import { Subject, takeUntil } from 'rxjs';
import { HelperServiceFacilisight } from '@/shared/_services/helper.service';
import { set } from 'd3';
declare var firebase;

@Component({
  selector: 'fc-protected',
  templateUrl: './protected.component.html',
  styleUrls: ['./protected.component.scss']
})
export class ProtectedComponent implements OnInit {

  message: any = {
    isExpanded: false
  };
  state: boolean = false;
  subscription: any;
  isDashBoardUrl: boolean = false;
  subscriptions: any = {};
  private unsubscribe: Subject<void> = new Subject();
  languagePreferenceEnable: boolean = false;

  constructor(
    public messageService: MessageService,
    public notificationService: NotificationsService,
    private helperService: HelperService,
    private configService: ConfigurationService,
    public commonService: CommonMethodService,
    public featureToggleService: FeatureToggleService,
    public helperServiceFs: HelperServiceFacilisight
  ) { }

  ngOnInit() {

    this.subscriptions['isDashboardUrl'] = this.helperService.isDashboardPage$.subscribe((_res) => {
      this.isDashBoardUrl = _res;
    })

    if (!localStorage.getItem('SelectedSite'))
      this.helperService.isDashboardPage$.next(true);

    this.subscription = this.messageService.getMessage().subscribe(message => this.message = message);
    this.firebaseInit();
    this.featureToggleService.flagChange.pipe(takeUntil(this.unsubscribe)).subscribe((flags)=>{
        this.setFlags(flags);
    })
    this.useFeatureToggles();
  }

  firebaseInit() {
    if(firebase) {
      let firebaseConfig = this.configService.getConfig('firebaseConfig');
      const self = this;
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig);
      if (firebase && firebase.messaging()) {
        // requesting permission to use push notifications
        firebase.messaging().requestPermission().then(() => {
          // getting FCM token
          //navigator.serviceWorker.getRegistrations().then(function(registrations) { for(let registration of registrations) { registration.unregister() } })
          if('serviceWorker' in navigator) {
            navigator.serviceWorker.register('./assets/js/firebase-messaging-sw.js',{scope: "/assets/js/firebase-cloud-messaging-push-scope"})
            .then(function(registration) {
              firebase.messaging().useServiceWorker(registration);
              firebase.messaging().getToken().then((fcmToken) => {
                self.registerPushNotification(fcmToken)
              }).catch((err) => {
                // can't get token
                console.log(err);
              });
            });
          }

        }).catch((err) => {
          console.log(err);
        });

        firebase.messaging().onMessage((payload) => {
          // ...
          navigator.serviceWorker.getRegistration('./assets/js/firebase-cloud-messaging-push-scope').then(registration => {

            const notificationTitle = payload.notification.title;
            const notificationOptions = {
              body: payload.notification.body,
              icon: "./favicon.ico"
            };
            registration.showNotification(
              notificationTitle,
              notificationOptions
            )
        });
        });
        firebase.messaging().onTokenRefresh((token) => {
          self.registerPushNotification(token);
        });

      } else {
        // no Firebase library imported or Firebase library wasn't correctly initialized
      }
    }
  }


  registerPushNotification(fcmToken) {
    this.notificationService.registerPushNotification(fcmToken).subscribe();
  }

  setFlags(flags) {
    this.languagePreferenceEnable = flags.hasOwnProperty('language-preference') ? flags['language-preference'].value: false;
    this.helperServiceFs.setLanguagePrefToggle(this.languagePreferenceEnable);
    if (this.languagePreferenceEnable) {
        this.loadGoogleTranslate();
    } else {
        document.body.classList.add('notranslate');
        this.commonService.applyPreselectedLanguage('en');
    }
  }
  useFeatureToggles() {
      let sub = this.featureToggleService.featureFlagsSubject.pipe(takeUntil(this.unsubscribe)).subscribe((flags)=>{
          this.setFlags(flags);
          sub.unsubscribe();
      }); 
      this.featureToggleService.getFlags();
  }

  loadGoogleTranslate() {
    window['gtranslateSettings'] = {
      "default_language": "",
      "detect_browser_language": false,
      "wrapper_selector": ".gtranslate_wrapper",
      "horizontal_position": "left",
      "vertical_position": "bottom",
      "select_language_label": "select"
  };
    const userData = JSON.parse(localStorage.getItem('user_preference'));
    const defaultLanguage = userData?.language || 'en';
    if(defaultLanguage == 'en') {
        document.body.classList.add('notranslate');
    } else {
        document.body.classList.remove('notranslate');
    }
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://cdn.gtranslate.net/widgets/latest/dropdown.js';
    script.onload = () => {
      console.log('Google translate script loaded');
      const intervalId = setInterval(() => {
        const select = document.querySelector('.goog-te-combo') as HTMLSelectElement;
        if (select && select.options.length > 0) {
          this.commonService.applyPreselectedLanguage(defaultLanguage);
          clearInterval(intervalId);
        }
      }, 500);
    }
    script.onerror = () => {
      console.log('Error loading google translate script');
    }
    document.head.appendChild(script);
  }



  ngOnDestroy() {
    const self = this;
    self.subscription.unsubscribe();
    this.unsubscribe.next();
    this.unsubscribe.complete();
    Object.keys(self.subscriptions).forEach(e => {
      self.subscriptions[e].unsubscribe();
    });
  }

}
