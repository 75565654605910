<div class="metrics-container">
    <div class="metrics-header">
        <div class="metrics-title-container">
            <h3 class="metrics-section-title">Metrics</h3>
            <p class="metrics-section-sub-title">Run diagnostic tests on each CCU to ensure optimal performance.</p>
        </div>
        <div class="spacer"></div>
        <div class="action-container">
            <button class="run-all-tests-button" id="run-all-tests" (click)="runTests()"
                [disabled]="isTestsRunning() || noCCUsFound" *ngIf="isRunEnabled && !isLoading">
                <div class="button-label">RUN ALL CCUs</div>
            </button>
        </div>
    </div>
    <div class="metrics-table-container">
        <!-- Actual Table  -->
        <table class="fs-metrics-table full-width-table">
            <thead class="fs-metrics-table__head">
                <tr class="fs-metrics-table__row head-row">
                    <th class="fs-metrics-table__cell head-cell ccuName">
                        <div class="fs-metrics-table__cell__data head-cell-data">CCU Name</div>
                    </th>
                    <th class="fs-metrics-table__cell head-cell score">
                        <div class="fs-metrics-table__cell__data head-cell-data">Score</div>
                    </th>
                    <th class="fs-metrics-table__cell head-cell run" *ngIf="isRunEnabled">
                        <div class="fs-metrics-table__cell__data head-cell-data">Run</div>
                    </th>
                </tr>
            </thead>
            <tbody class="fs-metrics-table__body" *ngIf="!isLoading && !noCCUsFound">
                <tr class="fs-metrics-table__row body-row" *ngFor="let row of tableData">

                    <!-- CCU Name -->
                    <td class="fs-metrics-table__cell ccuName body-cell">
                        <div class="fs-metrics-table__cell__data body-cell-data" showTooltipOnOverflow 
                            [matTooltip]="row.ccuName" [matTooltipPosition]="'above'">
                            {{ row.ccuName }}
                        </div>
                    </td>

                    <!-- Score -->
                    <td class="fs-metrics-table__cell score body-cell">
                        <!-- When status is SCHEDULED or RUNNING-->
                        
                        <div *ngIf="row.status === 'SCHEDULED' || row.status === 'RUNNING'; else scoreAvailable">
                            <div class="score-container scheduled">
                                <!-- <div class="progress-bar placeholder-bar"></div> -->
                                 <div class="progress-placeholder">
                                     <ngx-skeleton-loader></ngx-skeleton-loader>
                                 </div>
                                <span class="generating-text">Generating Score</span>
                            </div>
                        </div>
                      
                        <!-- When score is available -->
                        <ng-template #scoreAvailable>
                            <ng-container *ngIf="(row.score.value === 0 || row.score.value); else noScore">
                                <div class="score-container">
                                    <div class="progress-container">
                                        <div class="progress-bar"
                                            [style.width.%]="row.score.value * 100"
                                            [class.excellent]="row.rating === 'EXCELLENT'"
                                            [class.good]="row.rating === 'GOOD'"
                                            [class.needs-focus]="row.rating === 'NEEDS FOCUS'"
                                        ></div>
                                    </div>
                                    <span class="score-text"
                                        [class.excellent]="row.rating === 'EXCELLENT'"
                                        [class.good]="row.rating === 'GOOD'"
                                        [class.needs-focus]="row.rating === 'NEEDS FOCUS'"
                                    >
                                        {{ row.rating | titlecase }} ({{ (row.score.value * 100) | number:'1.0-0'}}%)
                                    </span>
                                </div>
                            </ng-container>
                      
                            <!-- When score is not available -->
                            <ng-template #noScore>
                                <!-- <span>{{row.score}}</span> -->
                                <span class="no-score-text">Run Test to check CCU's performance.</span>
                            </ng-template>
                        </ng-template>
                    </td>

                    <!-- Run -->
                    <td class="fs-metrics-table__cell icon-cell run body-cell" *ngIf="isRunEnabled">
                        <!-- When status is SCHEDULED or RUNNING-->
                        <div class="run-scheduled" *ngIf="row.status === 'SCHEDULED' || row.status === 'RUNNING'; else completedOrDefault">
                            <div class="timer-container" *ngIf="(this.showCountdownTimer || row.status === 'RUNNING') && getRemainingTime(row) !== null">
                                <countdown id="checkout-countdown-timer" [config]="getRemainingTime(row)"  (event)="handleCountdownEvent($event, row)"></countdown>
                            </div>
                            <div class="cancel-btn-container" id="cancel-checkout-button" (click)="cancelRun(row)">
                                <img class="cancel-icon test-action-button"
                                    src="./assets/images/close-icon.svg" alt="Cancel Test"/>
                            </div>
                        </div>

                        <!-- When status is COMPLETED or no status -->
                        <ng-template #completedOrDefault>
                            <div class="run-btn-container">
                                <img class="run-icon test-action-button" id="trigger-checkout-button"
                                    src="./assets/images/run-action.svg" alt="Run Test"
                                    (click)="runTests(row)"
                                />
                            </div>
                        </ng-template>
                    </td>

                </tr>
            </tbody>
        </table>
        
        <div *ngIf="noCCUsFound && !isLoading" class="placeholder-text">
            <p>{{ placeholderMsg }}</p>
        </div>
        
        <!-- Skeleton Loader -->
        <div *ngIf="isLoading" class="skeleton-loader-container">
            <table class="full-width-table">
                <tbody>
                    <tr *ngFor="let row of skeletonRows">
                        <ngx-skeleton-loader></ngx-skeleton-loader>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>


<!-- Export Mertrices content not shown in UI -->

    <div id="es-metrices-table" class="metrics-table-container-excel">
        <table class="fs-metrics-table full-width-table">
            <thead class="fs-metrics-table__head">
                <tr class="fs-metrics-table__row head-row" id="es-metrics-table-row-header">
                    <th class="fs-metrics-table__cell head-cell ccuName">
                        <div class="fs-metrics-table__cell__data head-cell-data">CCU Name</div>
                    </th>
                    <th class="fs-metrics-table__cell head-cell score">
                        <div class="fs-metrics-table__cell__data head-cell-data">Score</div>
                    </th>
                </tr>
            </thead>
            <tbody class="fs-metrics-table__body" *ngIf="!isLoading && !noCCUsFound">
                <tr class="fs-metrics-table__row body-row" *ngFor="let row of tableData, let i = index" id="es-metrics-table-ccu-{{i}}">

                    <!-- CCU Name -->
                    <td class="fs-metrics-table__cell ccuName body-cell">
                        <div class="fs-metrics-table__cell__data body-cell-data" showTooltipOnOverflow 
                            [matTooltip]="" [matTooltipPosition]="'above'">
                            {{ row.ccuName }}
                        </div>
                    </td>

                    <!-- Score -->
                    <td class="fs-metrics-table__cell score body-cell">
                        <div *ngTemplateOutlet="scoreAvailable"></div>
                                              
                        <!-- When score is available -->
                        <ng-template #scoreAvailable>
                            <ng-container *ngIf="(row.score.value === 0 || row.score.value); else noScore">
                                <div class="score-container">
                                    <div class="progress-container">
                                        <div class="progress-bar"
                                            [style.width.%]="row.score.value * 100"
                                            [class.excellent]="row.rating === 'EXCELLENT'"
                                            [class.good]="row.rating === 'GOOD'"
                                            [class.needs-focus]="row.rating === 'NEEDS FOCUS'"
                                        ></div>
                                    </div>
                                    <span class="score-text"
                                        [class.excellent]="row.rating === 'EXCELLENT'"
                                        [class.good]="row.rating === 'GOOD'"
                                        [class.needs-focus]="row.rating === 'NEEDS FOCUS'"
                                    >
                                        {{ row.rating | titlecase }} ({{ (row.score.value * 100) | number:'1.0-0'}}%)
                                    </span>
                                </div>
                            </ng-container>
                      
                            <!-- When score is not available -->
                            <ng-template #noScore>
                                <!-- <span>{{row.score}}</span> -->
                                <span class="no-score-text">Run Test to check CCU's performance.</span>
                            </ng-template>
                        </ng-template>
                    </td>

                </tr>
            </tbody>
        </table>
    </div>
</div>